.header{
    height: 75px;
    display: flex;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;    
    padding: 5px 15px;
    &-img{
        width: 180px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    &-text{
        font-size: 1.8rem;
        font-weight: 600;
    }
    &-link{
        &-btn {
            font-weight: 600;
            margin-left: 15px;
            cursor: pointer;
            &:hover{
                color: rgb(84, 84, 84);
            }
        }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0% 10%;
    &-logout{
       font-size: 40px;
       cursor: pointer;
       color: rgb(7, 194, 132);
    }
}