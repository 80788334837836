.bannerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 80%;
    margin: 40px 0px;
  }
  
  .banner {
    position: relative;
    height: 100%;
    max-height:198px;
    width: 100%;
    max-width: 1279px;
    background: none;
    overflow: hidden;
  }
  
  .banner .content {
    display: flex;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-elements: center;
  }
  
  .banner .content-wrapper {
    margin: auto;
    width: 100%;
    max-width: 706px;
    padding: 30px;
  }
  
  .banner .illo {
    z-index: 1;
    position: absolute;
    right: auto;
    left: 0;
    top: 0;
  }
  
  .button {
    display: block;
    margin-top: 16px;
    padding: 5px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: center;
    background: linear-gradient(65deg, #0270d7 0, #0f8afd 100%);
    
  }
  
  @media (min-width: 650px) {
    .banner .illo {
      right: 0;
      left: auto;
    }
  }
  
  @media (min-width: 1000px) {
    .banner .illo {
      right: 260px;
      left: auto;
    }
    
    .banner .content-wrapper {
      display: flex;
      max-width: 100%;
      align-items: stretch;
      justify-content: space-around;
    }
    
    .banner .content-wrapper .headline {
      flex-grow: 1;
      padding-left: 80px;
    }
    
    .button {
      flex: 0 0 200px;
      text-align: center;
    }
    
  }
  .headline{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    &-text{
        margin-top: 13px;
        font-size: 1.8rem;
        color: #ffff;
      }
  }
  