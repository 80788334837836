.container{
    display: flex;
    align-items: center;
    flex-direction: column;
    &-hero{
        height: 80vh;
        display: flex;
        padding: 0px 10%;
        
        &-left{
            height: 100%;
            width: 45%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            text-align: start;
            font-family: 'Roboto', sans-serif;
            &-title{
                font-size: 3rem;
                font-weight: 500;
                margin-bottom: 45px;
                // margin: 18px 0px;
            }
            &-subtitle{
                font-size: 1.3rem;
                font-weight: 200;   
                margin-bottom: 40px;
            }
            &-subheading{
                font-size: 2rem;
                font-weight: 400;   
                margin-bottom: 25px;
            }
            &-button {
                background:  linear-gradient(65deg, #0270d7 0, #0f8afd 100%);
                transition: all 0.5s ease;
                color: white;
                padding: 8px 25px;
                cursor: pointer;
                font-size: 1.2rem;
                text-decoration: none;
                &:hover {
                    transform: scale(1.1);
                }
                
            }
        }
        &-right{
            display: flex;
            align-items: center;
            width: 55%;
           &-img{
            width: 100%;
           }
        }

        @media only screen and (max-width: 870px) {
            flex-direction: column;
            &-left{
                width: 100%;
                margin: 15px 0px;
            }
            &-right {
                width: 100%;
            }
          }
    }
    &-features{
        text-align: center;
        width: 80%;
        padding: 60px 0px;
        &-title{
            padding: 15px 0px;
            margin-bottom: 15px;
            font-size: 2.5rem;
            display: block;
            font-weight: bold;
            color: #0270d7;
        }
        &-grid {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: 30px;
            &-card {
                width: 250px;
                height: 250px;
                padding: 0px 8px;
                background: #f7f7f7;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
                transition: all 0.5s ease;
                border-radius: 5px;
                &:hover{
                    transform: translateY(-16px);
                    cursor: default;
                }
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: start;
                &-title{
                    text-align: center;
                    margin-bottom: 15px;
                    font-size: 1.4rem;
                    font-weight: bold;
                    // color: #0270d7;
                }
                &-text{
                    font-size: 1.1rem;
                    text-align: justify;
                    font-weight: 350;
                }
              }
          }

    }
    &-info{
        text-align: center;
        width: 80%;
        padding: 60px 0px;
        &-title{
            padding: 15px 0px;
            margin-bottom: 35px;
            font-size: 2.5rem;
            display: block;
            font-weight: bold;
            color: #0270d7;
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            &-item{
                cursor: default;
                
                text-align: start;
                padding: 20px 15px;
                text-align: justify;
                width: 300px;
                margin-bottom: 10px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                font-size: 1.1rem;
                font-weight: 400;
                transition: all 0.5s ease;
                &:hover{
                    transform: translateY(-16px);
                    cursor: default;
                }
                &-heading{
                    display: block;
                    text-align: center;
                    font-size: 1.5rem;
                    margin-bottom: 20px;
                    font-weight: 500;
                }
            }
        }
    }
}