.footer{
    display: flex;
    flex-direction: column;
    margin: 0px 10%;
    justify-content: center;
    align-items: center;
    border-top: 1px solid gray;
    margin-top: 20px;
    padding-top: 20px;
    &-text{
        display: none;
        list-style: none;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        padding: 5px 0px;
        margin: 0px;

        li{
            font-size: 1.1rem;
            margin-right: 9px;
            a{
                text-decoration: none;
            }
        }
    }
    &-container{
        display: flex;
        width: 100%;
        padding-bottom: 15px;
        justify-content: space-between;
        
        &-icon{
            width: 50%;
            justify-content: flex-start;
            display: flex;
            list-style: none;
            padding: 5px 0px;
            margin: 0px;
            li{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 8px;
                width: 40px;
                height: 40px;
                background-color: rgb(243, 243, 243);
                border-radius: 50%;
                cursor: pointer;
            }
        }
        &-copyright{
            width: 50%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        @media only screen and (max-width: 870px) {
            flex-direction: column;
            &-icon {
                justify-content: space-around;
                width: 100%;
                margin-bottom: 15px;
            }
            &-copyright {
                width: 100%;
            }
          }
    }
}