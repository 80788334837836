.container{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 20px 0px;
    &-inner{
        width: 70%;
        display: flex;
        height: 70vh;
        flex-direction: column;
        align-items: center;
        &-heading{
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          &-row{
            display: flex;
          // flex-direction: column;
          justify-content: space-between;
          &-label{
            font-size: 18px;
            input:checked{
              border-color: #37AEE2;
              background-color: #37AEE2;
              color: white; 
            }

          }
          
          }
          &-title{
            font-weight: 400;
            font-size: 1.1rem;
            &-heading{
              font-weight: 600;
              font-size: 2rem;
              margin-bottom: 25px;
            }
          }
        }
        &-textInput {
          margin-left: 20px;
            height: 120px;
            width: 100%;
            resize: none;
            border-radius: 5px;
            padding: 15px;
            font-size: 1.1rem;
            margin-bottom: 20px;
        }
        &-head{
            width: 100%;
            justify-content: space-between;
            margin: 20px 20px;
            display: flex;
            align-items: center;

            &-select{
                width: 170px;
            }
        &-title{
            font-weight: 400;
            font-size: 1.1rem;
            &-heading{
              font-weight: 600;
              font-size: 2rem;
            }
        }
    }

        &-button {
          display: flex;
          width: 100%;
          margin-left: -13px;
            &-access{
                    margin: 15px 0px;
                 
                    
                    background-image: linear-gradient(-180deg, #37AEE2 0%, #1E96C8 100%);
                    border-radius: .5rem;
                    box-sizing: border-box;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    justify-content: center;
                    // padding: 0.5rem 1rem;
                    text-decoration: none;
                    width: 200px;
                    height: 45px;
                    border: 0;
                    cursor: pointer;
                    user-select: none;
                    -webkit-user-select: none;
                    touch-action: manipulation;
                  
                  
                  &:hover {
                    background-image: linear-gradient(-180deg, #1D95C9 0%, #17759C 100%);
                  }
                  &-disable{
                    background-color: lightgray;
                    margin: 15px 0px;
                    margin-right: 10px;
                    // background-image: linear-gradient(-180deg, #37AEE2 0%, #1E96C8 100%);
                    border-radius: .5rem;
                    box-sizing: border-box;
                    color: black;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    justify-content: center;
                    // padding: 0.5rem 1rem;
                    text-decoration: none;
                    pointer-events: none;
                    width: 200px;
                    height: 45px;
                    border: 0;
                    cursor: not-allowed;
                    user-select: none;
                    -webkit-user-select: none;
                    touch-action: manipulation;
                  
                  }
                  
                
            }
        }
    }
}

.lds-ring {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    height: 40px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 8px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .linkBtn{
    text-decoration: none;
  }