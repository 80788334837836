.chatbotContainer {
  // height: 80vh;
  width: 100%;
  margin: 20px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  @media only screen and (max-width: 870px) {
   flex-direction: column;
  }
  &-text{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 20%;
    padding: 5px 45px;
    margin: 0px 15px;
    @media only screen and (max-width: 870px) {
     margin: 0px;
     width: 100%;
    }
  
    font-size: 1.9rem;
   
  }
  &-chatbot {
    // font-family: mon
    // width: 70%;
    font-weight: 600;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    padding: 20px;
    // box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    // background: #f5f8fb;
    text-align: center;
    display: flex;
    flex-flow: column;
    width: 60%;
    overflow: hidden;
    @media only screen and (max-width: 870px) {
      width: 100%;
    }

  }
}



// .header {
//   padding: 20px;
//   text-align: center;
//   color: rgb(255, 255, 255);
//   max-height: 58px;
//   font-size: 16px;
//   font-weight: bold;
//   background-color: #002f50;
// }

.messages {
  width: 100%;
  height: 50vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}

.message-container {
  width: 100%;
}

.bot-message {
  float: left;
  // padding: 15px 20px;
  margin: 15px 0px;
  // border-radius: 20px 20px 20px 1px;
  // background: #00aaa5;
  // color: white;
  width: 95%;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  min-width: 40px;
  text-align: start;
  font-weight: 400;
}

.icon {
  width: 40px;
  margin-right: 10px;
  margin-bottom: auto;
}

.user-message {
  float: left;
  // padding: 15px 10px;
  font-size: 1.1rem;
  text-align: start;
  margin: 15px 0px;
  display: flex;
  align-items: center;
  width: 95%;
  // border-radius: 20px 20px 1px 20px;
  // background: #cccccc;
  color: black;
}

.input {
  margin-top: 40px;
  padding: 5px;
}
.form {
  display: flex;
}
.input > form > input[type="text"] {
  font-size: 16px;
  opacity: 1;
  outline: none;
  padding: 16px 52px 16px 10px;
  width: 100%;
}

.input > form > input:focus {
  outline: none;
}

.input > form > button {
  background-color: transparent;
  border-left: none;
  cursor: pointer;
  fill: #4a4a4a;
  opacity: 1;
  outline: none;
  padding: 14px 16px 12px 16px;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.loading::after {
  content: "";
  width: 5px;
  height: 25px;
  background: #0a0a0a;
  display: inline-block;
  animation: cursor-blink 1.2s steps(2) infinite;
}

.msg-container{
  display: flex;
  flex-direction: column;
  &-button {

    padding: 5px 10px;
    color: white;
    background-color: rgb(50, 129, 151);
    border-radius: 5px;
    margin-bottom: 8px;
    cursor: pointer;
  }
}